<template>
    <p class="h20"></p>
    <div v-if="type=='sch'" class="padb20" >
        <el-autocomplete
            class="inline-input w540"
            v-model="paramsch.searchContent"
            size="large"
            :trigger-on-focus="false"
            @keyup.enter="getlist" 
            :fetch-suggestions="querySearch"
            placeholder="请输入文档所属产品、模块或者子模块名称"
            @select="handleSelect"
            >
            <template #suffix>
                <div @click="getlist" class="flex flexa padr10 point" style="height:100%" >
                    <el-icon class="el-icon-search"></el-icon>
                </div>
            </template>
        </el-autocomplete>
        <!-- <div class="flex padt10 ">
            <p class="cola1 padr20 nowrap">搜索历史</p>
            <div class="history">
                <span v-for="(item,index) in 3" :key="index" class="">CVM数据库</span>
            </div>
        </div> -->
    </div>

    <div class="bgf search">
        <div>
            <el-cascader
                v-model="threeL"
                size="medium"
                separator="  >  "
                class="w464"
                :props="props"
                placeholder="请选择"
                :options="threeCate"
                @change="handleChange">
            </el-cascader>

            <el-select @change="cgedoctype" v-model="param.docType" size="medium" class="padl30" placeholder="">
                <el-option label="全部类型文档" value=""></el-option>
                <el-option label="知识文档" value="1"></el-option>
                <el-option label="BUG文档" value="2"></el-option>
            </el-select>
        </div>
        <ul class="ul1">
            <li @click="$router.push('/knowledgeInfo/'+ item.id )" v-for="(item,index) in list" :key="index" >
                <p class="col1582 font18 padb14">{{item.docTitle}}</p>
                <div class="text2 font14 lh28 col68">
                    {{ utils.markdonTxt(item.docProfile)}}
                </div>
                <p class="col9c padt10">来自：{{item.categoryName}}</p>
            </li>
        </ul>

        <div class="tc padt40">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="param.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="param.pageSize"
            layout=" prev, pager, next, sizes, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { threeCategoryKnowledgeList, searchKnowledgeResult, recommended } from "@/utils/api1.js"
import { getOneCategoty, getTwoCategory, getThreeCategory } from "@/utils/api2.js"

  export default {
    data() {
      return {
        param:{
            docType: '',
            pageNo: 1,
            pageSize: 10 ,
            threeCategoryId: this.$route.params.id
        },
        paramsch:{
            searchContent: '',
            isShow: 1,
        },
        threeCate: [],
        threeL:[ ],

        list:[],
        total: 0,
        type: this.$route.params.type,
        currentPage4: 1,
        value: [],
        value1: '',
      
        valueL:[],
        props:{
            value: 'id',
            label: 'title',
            children: 'threeCategoryList',
            lazy: true,
            lazyLoad (node, resolve) {
                const { level } = node;
                console.log(node);
                console.log(level);
                if(level==1){
                    const id  = node.data.id;
                    // console.log(id);
                    getTwoCategory(id).then(res=>{
                        console.log(res);
                        if(res){
                            resolve(res);
                        }
                    });
                }else if(level==2){
                    const id2  = node.data.id;
                    // console.log(id);
                    getThreeCategory(id2).then(res=>{
                        console.log(res);
                        if(res){
                            res.map(val=>{
                                val.leaf = true
                            })
                            resolve(res);
                        }
                    });
                }
            }
        },
        options: [ ],
        myquery:{},
      };
    },
    mounted(){
        var that = this ;
        
        if(that.type=='sch'){
            that.myquery = that.$route.query;
            let dat =  that.$route.query;
            that.paramsch.searchContent = dat.value
            console.log(that.paramsch.searchContent);
            if(dat.type=='3'){
                that.paramsch.threeCategoryId = dat.id;
            }
        }
        this.getCate();
        this.getlist();
    },
    methods: {
        getlist(){
            var that = this;
            if(that.type=='cate'){
                console.log(that.param);
                threeCategoryKnowledgeList(that.param).then(res=>{
                    console.log(res);
                    if(res){
                        that.list = res.voList;
                        that.total = res.total;
                    }
                })
            }else{
                that.paramsch.pageNo = that.param.pageNo;
                that.paramsch.pageSize = that.param.pageSize;
                that.paramsch.knowledgeType = that.param.docType;

                console.log(that.paramsch);
                searchKnowledgeResult(that.paramsch).then(res=>{
                    console.log(res);
                    if(res){
                        that.list = res.voList;
                        that.total = res.total;
                    }
                })

            }
        },

        querySearch( txt ,cb ){
            console.log(txt);
            if(txt){
                recommended({searchContent:txt, isShow: 1}).then(res=>{
                    console.log(res);
                    if(res){
                        let result = res.map((terminal) => {
                            return {
                                value: terminal.categoryTitle,
                                id: terminal.id,
                                type: terminal.type
                            };
                        });
                        cb(result)
                    }
                })
            }

        },

        handleSelect(val){
            console.log(val);
            this.param.pageNo= 1;
            this.getlist();
        },

        // 获取三级分类列表
        getCate(){

            
            var that = this;
            getOneCategoty(3).then(res=>{
                console.log(res)
                if(res){
                    that.threeCate = res;
                }
            });
            // }
            
        },

        handleChange(value) {
            console.log(value);
            if(this.type=='cate'){
                this.param.threeCategoryId = value[2];
            }else{
                this.paramsch.threeCategoryId = value[2];
            }
            this.getlist();
        },

        cgedoctype(val){
            console.log(val);
            this.param.pageNo= 1;
            this.getlist()
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            // this.param.pageNo= 1;
            this.param.pageSize = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.param.pageNo = val;
            this.getlist();
        },
    }
  };
</script>

<style>

</style>

<style lang="scss" scoped>
.search{
    padding: 30px 20px 47px;
}
.ul1{
    li{
        padding: 30px 0;
        border-bottom: 1px dashed #E6E6E6;
        cursor: pointer;
    }
}
.history{
    >span{
        display: inline-block;
        padding: 0 16px 8px 0;
        color: #444;
        white-space: nowrap;
        cursor: pointer;
    }
    span:hover{
        color: #FF6A00;
    }
}
</style>